@font-face {
    font-family: 'Arial Unicode MS';
    src: url('../public/Arial-Unicode-MS.ttf') format('truetype');
}


body {
    margin: 0;
    background: white;
    font-family: 'Arial Unicode MS', sans-serif !important;
}
.container{
    display: grid;
    height: 100vh;
    place-items: center;
}
.exchange{
    display: flex;
    flex-wrap: wrap;

}
.rate{
    flex: 0 0 calc(50% - 2px);
    display: flex;
    flex-direction: row;
    border:1px solid black;
}
.rate div{
    font-size: 4vw;
    text-align: center;
    flex:1;
    display: grid;
    place-items: center;
}
.rate.title *{
    line-height: 5vh;
    font-size: 3vw!important;
}
.cur{
    display: flex!important;
    gap: 1vw;
    flex-direction: row;
}
input{
    font-size: 1.5vw;
    width: 50%;
}
.rate + .rate {
    margin-top: -1px; /* Adjust according to border thickness */
}
.flag{
    width: 8vw;
    margin: 8px;
    aspect-ratio: 3/2 ;
}
.title{
}
.info{
    font-size: 2vw;
}
.info .flag{
    opacity: 0;
}
.info *{
    padding-left: 8px;
}
.buy, .sell{
    font-weight: bolder;
}